// function _PreLoadImg(b, e) {
//     var c = 0, a = {}, d = 0;
//     for (src in b) {
//          d++ 
//     }
//     for (src in b) {
//      a[src] = new Image();
//      a[src].onload = function () {
//           if (++c >= d) {
//                e(a) 
//             } 
//         }; 
//      a[src].src = b[src]
//     }
// };
// $(function(){

// 	var src = $('.sub-banner img').attr('src');
// 	_PreLoadImg([src],function(){
// 		$('.sub-banner').height($(window).height());
// 		$(window).on('resize',function(){
// 			$('.sub-banner').height($(window).height());
// 		});
// 		FullBg($(".sub-banner"), $(".sub-banner .bimg"));
		
// 	});
// });
window.onload=function(){
	$('.preload').fadeOut(500);
	pageLoad();
	// $('#ser .ind-nav .line').addClass('on2');
}
function pageLoad(){
	setTimeout(function(){
		$('.load-bg .top i').addClass('on');
		setTimeout(function(){
			$('.load-bg .top i').fadeOut(50);
			$('.load-bg .top').stop().animate({'height':'0'},1000);
			$('.load-bg .bot').stop().animate({'height':'0'},1000);
			$('.load-bg').fadeOut(1000);
		},500)
	},700)
}